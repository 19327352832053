export default {
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    getWidth() {
      if (window.outerWidth < 450) return 1;
      else if (window.outerWidth < 780) return 2;
      else return 3;
    },
  },
};
