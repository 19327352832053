
import MixLocales from "@/mixins/MixLocales.js";
export default {
  mixins: [MixLocales],
  computed: {
    menus() {
      return this.$store.state.menus;
    },
  },
};
